import config from '@packages/config';
import { cleanPathname } from './cleanPathName';

/**
 * fetch cms data by url. This may throw an exception when fetch response is not ok.
 *
 * @param url URL to fetch from
 * @param token optional bearer-token to access the API
 * @returns data fetched as drupal object or string array of empty promotions
 * @throws Error when fetch response is not ok
 */
const fetcher = async <T>(
  url: string,
  token?: string,
  additionalHeaders?: HeadersInit,
): Promise<T> => {
  const headers: HeadersInit = {
    'content-type': 'application/vnd.api+json',
    // for log debugging. to identify which application has fetched
    'User-Agent': `fetch/cms-content-turbo-api`,
    user_agent: `fetch/cms-content-turbo-api`,
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(url, {
    headers: { ...headers, ...additionalHeaders },
  })
    .then((response) => {
      if (response.status === 404) {
        return null;
      }
      // ok: true if status 2xx
      if (!response.ok) {
        // throw error when response status not ok -> make it public for debugging
        const error = new Error(
          `Endpoint: ${response.url} / Status: ${response.status} / Status Text: ${response.statusText}`,
        );
        error.cause = { info: response.statusText, status: response.status };
        throw error;
      }

      return response.json();
    })
    .then((resData) => {
      // do prettier alternateLinks if exists for this fetched data-type
      if (resData && 'alternateLinks' in resData) {
        const data = resData;
        const headerInformationByConfig = config.headerInformation;
        // setup production hostname and cleanup url path for placing in html head alternate links
        data.alternateLinks =
          headerInformationByConfig &&
          resData.alternateLinks &&
          Object.fromEntries(
            Object.entries<string>(resData.alternateLinks).map(([language, link]) => {
              const headerInfo = headerInformationByConfig[language as 'de' | 'fr'];
              const alternateUrl = new URL(link);
              const canonicalUrl = headerInfo?.canonical && new URL(headerInfo?.canonical);
              alternateUrl.hostname =
                typeof canonicalUrl === 'object' ? canonicalUrl?.hostname : alternateUrl.hostname;
              alternateUrl.pathname = cleanPathname(alternateUrl.pathname);
              return [language, alternateUrl.toString()];
            }),
          );
        return data;
      }
      return resData;
    })
    .then((resData: T) => resData);
};

/**
 * Fetches data from a specified endpoint and revalidates the data at a specified interval.
 *
 * @template T The expected return type from the fetch request.
 * @param {string} endpoint The URL from which to fetch data.
 * @param {number} [revalidateTime=60] The time in seconds at which to revalidate the data. Defaults to 60 seconds.
 */
export const bucketFetcher =
  <T>(endpoint: string, revalidateTime: number = 60) =>
  (): Promise<T> =>
    fetch(endpoint, {
      next: { revalidate: revalidateTime },
    }).then((res) => {
      if (!res.ok) {
        // throw error when response status not ok -> error boundary catches and logs the error
        throw new Error(`Endpoint: ${res.url} - Status ${res.status}: ${res.statusText}`);
      }
      return res.json();
    });

export { fetcher };
